<div class="big-text">
  <div class="big-text__wrapper" [ngClass]="'set--' + align">
    <h2 class="display-1 big-text__title mb-0" [attr.data-speed]="speed" *ngIf="!splitLines">{{ text }}</h2>

    <ng-container *ngIf="splitLines">
      <h2
        *ngFor="let line of splittedLines; let i = index"
        [class.is--odd]="i % 2 === 0"
        [class.is--even]="i % 2 !== 0"
        class="display-1 big-text__title mb-0"
        [attr.data-speed]="speed"
      >
        {{ line }}
      </h2>
    </ng-container>
  </div>
</div>
