<div
  class="text-shape"
  [ngClass]="cssClasses + ' is--' + useShape"
  [class.has--text]="text"
  [attr.aria-hidden]="!text"
  [style.--text-shape-delay]="delay"
>
  <span class="text-shape__label" *ngIf="text">{{ text }}</span>

  <!--<img *ngIf="!isAnimatedShape" class="text-shape__shape" [src]="'/assets/gfx/squiggles/' + useShape + '.svg'" />-->

  <ng-lottie
    class="text-shape__shape"
    [ngClass]="'fill-' + (shapeColor || 'primary') + ' type--' + type"
    [options]="options$ | async"
    [class.is--mirrored]="mirror"
    #lottie
    (animationCreated)="playerSettings($event)"
  ></ng-lottie>
</div>
