<div class="main-nav">
  <div class="main-nav__bar topbar" [ngClass]="'theme--' + theme" [class.is--collapsed]="isCollapsed">
    <div class="container container-size topbar__inner">
      <a [routerLink]="'/'" class="topbar__logo block-link" (click)="closeOverlay.emit()" [class.text-dark]="menuActive">
        <span class="sr-only">Home</span>
        <app-logo [theme]="theme"></app-logo>
      </a>

      <div class="topbar__actions">
        <div class="d-none d-md-inline-block">
          <ul class="topbar-menu-list">
            <li class="topbar-menu-list__item" *ngFor="let page of quickMenu; let i = index" [style.--list-item-index]="i">
              <fstr-link
                cssClasses="nav-link"
                [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
                [routerLinkActive]="'nav-link--active'"
                [routerLinkActiveOptions]="{ exact: page.url === '/' }"
              ></fstr-link>
            </li>
          </ul>
        </div>

        <button class="hamburger-button" (click)="toggleOverlay.emit()" [class.halftone-shape-overlay]="menuActive">
          <span *ngIf="!menuActive">{{ 'topbar-menu-knop-tekst' | fromDictionary }}</span>
          <span class="text-light" *ngIf="menuActive">{{ 'topbar-menu-knop-tekst-sluiten' | fromDictionary }}</span>
          <div class="hamburger" [class.is--active]="menuActive">
            <span class="hamburger__lines"></span>
          </div>
        </button>
      </div>
    </div>
  </div>

  <nav class="main-nav__overlay nav-overlay" [class.is--open]="menuActive">
    <div class="container container-size pt-topbar nav-overlay__inner">
      <div class="row w-fill">
        <div class="col-md-6 col-xl-4 nav-overlay__meta">
          <dl class="w-100">
            <dt class="text-primary type-body-xs">{{ 'footer-telefoonnummer-label' | fromDictionary }}</dt>
            <dd class="fs-5">
              <a class="text-dark text-decoration-none" [href]="'tel:' + ('brilliant-orange-telefoonnummer' | fromDictionary)">
                {{ 'brilliant-orange-telefoonnummer' | fromDictionary }}
              </a>
            </dd>
          </dl>
          <dl class="w-100">
            <dt class="text-primary type-body-xs">{{ 'footer-email-label' | fromDictionary }}</dt>
            <dd class="fs-5">
              <a class="text-dark text-decoration-none" [href]="'mailto:' + ('brilliant-orange-emailadres' | fromDictionary)">
                {{ 'brilliant-orange-emailadres' | fromDictionary }}
              </a>
            </dd>
          </dl>
        </div>
        <div class="col-md-6 col-xl-8 nav-overlay__menu">
          <div class="nav-overlay__menu__inner">
            <ul class="menu-list halftone-shape-overlay--1">
              <li class="menu-list__item" *ngFor="let page of menu; let i = index" [style.--list-item-index]="i">
                <fstr-link
                  (click)="closeOverlay.emit()"
                  [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
                  [routerLinkActive]="'is--current'"
                  [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                  cssClasses="fs-display-2  menu-list__item__link mb-0"
                ></fstr-link>
              </li>
            </ul>

            <!-- halftone-shape-overlay -->
            <ul class="social-list" *ngIf="socials.length">
              <li class="social-list__item" *ngFor="let item of socials">
                <fstr-link
                  [cssClasses]="'button button-link button--light fs-body-md'"
                  [button]="{ text: item.title, url: item.url ? item.url : '' }"
                ></fstr-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
