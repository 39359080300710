import { createReducer, on } from '@ngrx/store';
import * as NavActions from '../actions/nav.action';

export interface NavState {
  menuOpen: boolean;
  hideFooterPayoff: boolean;
  menuTheme: string;
}

export const initialState: NavState = {
  menuOpen: false,
  hideFooterPayoff: false,
  menuTheme: 'dark',
};

export const NavReducer = createReducer(
  initialState,
  on(NavActions.SetNavTheme, (state, { theme }) => {
    return {
      ...state,
      menuTheme: theme,
    };
  }),
  on(NavActions.OpenMenu, state => {
    return {
      ...state,
      menuOpen: true,
    };
  }),
  on(NavActions.CloseMenu, state => {
    return {
      ...state,
      menuOpen: false,
    };
  }),
  on(NavActions.ToggleMenu, state => {
    return {
      ...state,
      menuOpen: !state.menuOpen,
    };
  }),
  on(NavActions.HideFooterPayoff, (state, { hide }) => {
    return {
      ...state,
      hideFooterPayoff: hide,
    };
  })
);

export const getMenuOpen = (state: NavState) => state.menuOpen;
export const getHideFooterPayoff = (state: NavState) => state.hideFooterPayoff;
