import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Shape } from 'src/app/standalone/text-shape/text-shape.component';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  transform(
    value: string,
    brandColor?: string,
    stripHighlight?: boolean,
    enableShape?: boolean,
    shape?: Shape,
    shapeColor?: string,
    shapeType?: 'arrow' | 'textbg' | 'standalone',
    shapeMirror?: boolean
  ): string {
    // To only remove []
    if (stripHighlight) {
      return (value || '').replace(/\[(.*?)\]/g, `$1`);
    }

    if (enableShape) {
      const result = (value || '').replace(
        /\[(.*?)\]/g,
        `<app-text-shape cssClasses="${brandColor}" shape="${shape || ''}" shapeColor="${shapeColor || 'secondary'}" text="$1" [mirror]="${
          shapeMirror || false
        }" type="${shapeMirror || 'textbg'}"></app-text-shape>`
      );
      return `<span class="shape-container">${result}</span>`;
    }

    // wrap in span
    return (value || '').replace(/\[(.*?)\]/g, `<span class="${brandColor || 'text-primary-gradient'}">$1</span>`);
  }
}
