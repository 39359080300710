import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewModule } from '@teamfoster/sdk/in-view';
import SplittingDirective from 'src/app/animations/directives/splitting.directive';

@Component({
  selector: 'app-big-text',
  standalone: true,
  imports: [CommonModule, InViewModule, SplittingDirective],
  templateUrl: './big-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BigTextComponent {
  @Input() text!: string;
  @Input() textSecondLine!: string;
  @Input() speed: number = 1.1;
  @Input() align: 'left' | 'right' | 'center' = 'center';
  @Input() splitLines: boolean = false;

  get splittedLines() {
    return this.splitArray(this.text.split(' '));
  }

  splitArray(array: string[]) {
    // Calculate the midpoint of the array
    var midpoint = Math.ceil(array.length / 2);

    // Use array slice to split the array into two parts
    var firstHalf = array.slice(0, midpoint);
    var secondHalf = array.slice(midpoint);

    return [firstHalf.join(' '), secondHalf.join(' ')];
  }
}
