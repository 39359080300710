import { Component, Input, ChangeDetectorRef, Output, EventEmitter, PLATFORM_ID, inject, effect, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { auditTime, debounceTime, filter, throttleTime } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';
import { SocialChannel } from 'src/app/social-media/models';
import { isPlatformBrowser } from '@angular/common';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit {
  private router = inject(Router);

  @Input() loading: boolean = false;
  @Input() menuActive: boolean = false;
  @Input() logoClass: string = '';
  @Input() menu: fromMenu.MenuItem[] = [];
  @Input() quickMenu: fromMenu.MenuItem[] = [];

  @Input() theme: 'dark' | 'light' | string = 'dark';

  @Input() socials: SocialChannel[] = [];

  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();

  cd = inject(ChangeDetectorRef);
  private platformId = inject(PLATFORM_ID);

  lastScrollTop = 0;
  scrollDirection: 'up' | 'down' = 'up';
  isCollapsed = false;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(
          throttleTime(100), // Emit the first event in the given time interval
          auditTime(100) // After the specified time, emit the last event observed
        )
        .subscribe(a => {
          const st = window.scrollY > 0 ? window.scrollY : 0;
          this.scrollDirection = st < this.lastScrollTop ? 'up' : 'down';
          this.lastScrollTop = st;

          this.isCollapsed = st > 100 && this.scrollDirection === 'down';
          this.cd.detectChanges();
        });
    }
  }
}
