<svg width="484" class="logo" height="172" viewBox="0 0 484 172" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M207.75 36.6699H217.72C224.64 36.6699 226.44 39.9799 226.44 43.0699C226.44 45.8299 224.34 47.7099 222.87 48.1799C224.71 48.7699 227.76 50.5299 227.76 54.1799C227.76 59.0699 224.3 61.2099 217.72 61.2099H207.75V36.6699ZM216.95 46.5299C218.46 46.5299 219.89 45.8699 219.89 43.9899C219.89 41.8899 218.35 41.3099 216.95 41.3099H214.12V46.5299H216.95ZM217.43 56.5699C218.94 56.5699 221.15 56.2699 221.15 53.6299C221.15 51.1599 218.94 50.7599 217.43 50.7599H214.12V56.5699H217.43Z"
    fill="currentColor"
  />
  <path
    d="M238.72 36.6699H248.88C254.14 36.6699 257.6 39.4299 257.6 44.7299C257.6 47.9699 256.06 50.3599 253.7 51.7899L258.96 61.2099H251.97L247.81 52.8599H245.09V61.2099H238.72V36.6699ZM248.25 47.7499C249.98 47.7499 251.16 46.6099 251.16 44.8399C251.16 42.9599 250.09 41.8199 248.22 41.8199H245.09V47.7399H248.25V47.7499Z"
    fill="currentColor"
  />
  <path d="M269.29 36.6699H275.8V61.2099H269.29V36.6699Z" fill="currentColor" />
  <path d="M287.95 36.6699H294.46V55.4699H305.83V61.2099H287.95V36.6699Z" fill="currentColor" />
  <path d="M315.82 36.6699H322.33V55.4699H333.7V61.2099H315.82V36.6699Z" fill="currentColor" />
  <path d="M343.69 36.6699H350.2V61.2099H343.69V36.6699Z" fill="currentColor" />
  <path
    d="M368.99 36.6699H376.46L385.14 61.2099H378.11L376.53 56.1299H368.33L366.71 61.2099H359.98L368.99 36.6699ZM375.21 51.3899L372.49 42.3799L369.66 51.3899H375.22H375.21Z"
    fill="currentColor"
  />
  <path d="M394.97 36.6699H400.89L410.31 50.3599V36.6699H416.75V61.2099H411.2L401.41 47.1199V61.2099H394.97V36.6699Z" fill="currentColor" />
  <path d="M434.3 42.3699H426.76V36.6699H448.36V42.3699H440.89V61.2099H434.3V42.3699Z" fill="currentColor" />
  <path
    d="M229.71 130.4C226.95 133.86 223.3 135.59 218.77 135.59C211.22 135.59 205.99 130.25 205.99 122.73C205.99 115.21 211.18 109.73 218.77 109.73C223.04 109.73 226.8 110.98 229.3 114.52L224.99 118.87C223.63 116.99 221.71 115.78 219.24 115.78C215.12 115.78 212.72 118.73 212.72 122.56C212.72 126.39 215.26 129.41 219.2 129.41C222.04 129.41 223.58 128.45 225.32 126.28L229.7 130.41L229.71 130.4Z"
    fill="currentColor"
  />
  <path d="M239.69 110.4H246.21V129.22H257.59V134.97H239.69V110.4Z" fill="currentColor" />
  <path
    d="M267.611 110.4H274.131V124.8C274.131 127.49 275.271 129.4 278.181 129.4C281.091 129.4 282.191 127.45 282.191 124.8V110.4H288.781V125.39C288.781 131.73 284.401 135.59 278.211 135.59C272.021 135.59 267.601 131.76 267.601 125.39V110.4H267.611Z"
    fill="currentColor"
  />
  <path
    d="M300.641 110.4H310.621C317.541 110.4 319.351 113.71 319.351 116.81C319.351 119.57 317.251 121.45 315.781 121.93C317.621 122.52 320.681 124.29 320.681 127.93C320.681 132.83 317.221 134.97 310.621 134.97H300.641V110.4ZM309.851 120.27C311.361 120.27 312.801 119.61 312.801 117.73C312.801 115.63 311.251 115.04 309.851 115.04H307.011V120.27H309.851ZM310.331 130.33C311.841 130.33 314.051 130.04 314.051 127.38C314.051 124.91 311.841 124.51 310.331 124.51H307.011V130.33H310.331Z"
    fill="currentColor"
  />
  <path
    d="M205.87 85.7499C205.87 78.2499 211.28 72.8799 218.63 72.8799C225.98 72.8799 231.39 78.2499 231.39 85.7499C231.39 93.2499 226.02 98.6599 218.63 98.6599C211.24 98.6599 205.87 93.2199 205.87 85.7499ZM224.66 85.7499C224.66 81.9999 222.45 78.9499 218.63 78.9499C214.81 78.9499 212.6 81.9999 212.6 85.7499C212.6 89.4999 214.81 92.5499 218.63 92.5499C222.45 92.5499 224.66 89.4999 224.66 85.7499Z"
    fill="currentColor"
  />
  <path
    d="M242.45 73.5398H252.6C257.86 73.5398 261.32 76.2998 261.32 81.5998C261.32 84.8398 259.78 87.2298 257.42 88.6598L262.68 98.0698H255.69L251.53 89.7198H248.81V98.0698H242.45V73.5398ZM251.98 84.6098C253.71 84.6098 254.89 83.4698 254.89 81.6998C254.89 79.8198 253.82 78.6798 251.95 78.6798H248.82V84.5998H251.98V84.6098Z"
    fill="currentColor"
  />
  <path
    d="M280.74 73.5398H288.21L296.89 98.0698H289.87L288.29 92.9998H280.09L278.47 98.0698H271.74L280.75 73.5398H280.74ZM286.96 88.2498L284.24 79.2398L281.41 88.2498H286.96Z"
    fill="currentColor"
  />
  <path d="M306.7 73.5398H312.62L322.03 87.2198V73.5398H328.47V98.0698H322.92L313.14 83.9898V98.0698H306.7V73.5398Z" fill="currentColor" />
  <path
    d="M353.04 84.0599H363.12V95.1299C360.18 97.6299 356.17 98.6599 352.34 98.6599C344.36 98.6599 339.58 93.6899 339.58 85.7499C339.58 78.2499 344.84 72.8799 352.34 72.8799C356.42 72.8799 360.14 74.1299 362.53 77.2499L358.3 81.5199C356.79 80.0099 355.14 78.9099 352.82 78.9099C348.63 78.9099 346.27 82.0299 346.27 85.8599C346.27 90.0499 348.88 92.7699 352.85 92.7699C354.47 92.7699 355.94 92.4399 357.3 91.4499V89.0999H353.03V84.0599H353.04Z"
    fill="currentColor"
  />
  <path
    d="M374.88 73.5398H392.53V79.1698H381.39V82.9598H390.55V88.2598H381.39V92.4498H393.31V98.0798H374.89V73.5498L374.88 73.5398Z"
    fill="currentColor"
  />
  <path
    d="M51.3604 119.26C51.3604 123.42 51.8803 127.45 52.8503 131.3C60.0403 138.92 69.0304 144.8 79.1504 148.27C70.2404 141.71 64.4503 131.15 64.4503 119.26C64.4503 111.96 66.6404 105.16 70.3904 99.48C68.6604 95.5 67.6904 91.1 67.6904 86.48C67.6904 85.12 67.7804 83.79 67.9404 82.48C57.7704 91.48 51.3604 104.62 51.3604 119.26Z"
    fill="url(#paint0_radial_249_1151)"
  />
  <path
    d="M133.25 119.26C133.25 113.2 131.6 107.53 128.73 102.66C126.35 106.82 123.08 110.41 119.18 113.17C119.81 115.09 120.16 117.14 120.16 119.26C120.16 130.12 111.33 138.95 100.47 138.95C89.6104 138.95 80.7804 130.12 80.7804 119.26C80.7804 117.18 81.1104 115.18 81.7104 113.3C77.7904 110.57 74.5004 107 72.0904 102.86C69.2904 107.69 67.6904 113.29 67.6904 119.26C67.6904 135.49 79.4904 148.96 94.9704 151.57C96.7504 151.71 98.5404 151.81 100.35 151.81C102.3 151.81 104.22 151.71 106.13 151.54C121.53 148.86 133.24 135.43 133.24 119.26H133.25Z"
    fill="url(#paint1_radial_249_1151)"
  />
  <path
    d="M100.47 37.49C75.5503 37.49 53.2403 48.66 38.2403 66.24C36.1603 72.62 35.0303 79.42 35.0303 86.49C35.0303 89.98 35.3103 93.41 35.8403 96.76C45.1703 70.04 70.6103 50.82 100.47 50.82C130.33 50.82 155.49 69.83 164.95 96.31C165.43 93.11 165.69 89.83 165.69 86.5C165.69 79.27 164.5 72.32 162.33 65.82C147.34 48.49 125.2 37.5 100.48 37.5L100.47 37.49Z"
    fill="url(#paint2_radial_249_1151)"
  />
  <path
    d="M100.471 135.71C109.551 135.71 116.921 128.35 116.921 119.26C116.921 117.77 116.701 116.34 116.331 114.97C111.611 117.63 106.161 119.15 100.351 119.15C94.5407 119.15 89.2507 117.67 84.5707 115.09C84.2207 116.43 84.0107 117.82 84.0107 119.27C84.0107 128.35 91.3707 135.72 100.461 135.72L100.471 135.71Z"
    fill="url(#paint3_radial_249_1151)"
  />
  <path
    d="M130.42 99.27C134.25 104.99 136.49 111.87 136.49 119.26C136.49 131.06 130.79 141.55 121.99 148.12C132.07 144.58 141.02 138.64 148.16 130.98C149.08 127.22 149.58 123.3 149.58 119.26C149.58 104.49 143.05 91.26 132.74 82.26C132.92 83.64 133.02 85.05 133.02 86.49C133.02 91.03 132.09 95.35 130.42 99.28V99.27Z"
    fill="url(#paint4_radial_249_1151)"
  />
  <path
    d="M159 57.6899C148.36 36.0499 126.1 21.1499 100.36 21.1499C74.62 21.1499 52.06 36.2399 41.5 58.1099C56.8 43.3499 77.59 34.2399 100.48 34.2399C123.37 34.2399 143.75 43.1699 159.01 57.6799L159 57.6899Z"
    fill="url(#paint5_radial_249_1151)"
  />
  <path
    d="M48.1202 119.26C48.1202 102.22 56.3102 87.0498 68.9602 77.4898C72.3602 65.5898 82.3402 56.4798 94.7102 54.3198C66.7802 56.7698 43.9002 76.8198 37.2402 103.32C39.4902 111.8 43.4102 119.59 48.6202 126.34C48.3102 124.02 48.1302 121.66 48.1302 119.26H48.1202Z"
    fill="url(#paint6_radial_249_1151)"
  />
  <path
    d="M105.84 54.2898C118.21 56.3798 128.22 65.4298 131.69 77.2698C144.5 86.8198 152.82 102.09 152.82 119.26C152.82 121.54 152.66 123.78 152.37 125.99C157.53 119.2 161.4 111.38 163.6 102.88C156.77 76.4698 133.79 56.5698 105.83 54.2898H105.84Z"
    fill="url(#paint7_radial_249_1151)"
  />
  <path
    d="M51.3604 118.85C51.3604 123.01 51.8803 127.04 52.8503 130.89C60.0403 138.51 69.0304 144.39 79.1504 147.86C70.2404 141.3 64.4503 130.74 64.4503 118.85C64.4503 111.55 66.6404 104.75 70.3904 99.0701C68.6604 95.0901 67.6904 90.6901 67.6904 86.0701C67.6904 84.7101 67.7804 83.3801 67.9404 82.0701C57.7704 91.0701 51.3604 104.21 51.3604 118.85Z"
    fill="url(#paint8_radial_249_1151)"
  />
  <path
    d="M133.25 118.85C133.25 112.79 131.6 107.12 128.73 102.25C126.35 106.41 123.08 110 119.18 112.76C119.81 114.68 120.16 116.73 120.16 118.85C120.16 129.71 111.33 138.54 100.47 138.54C89.6104 138.54 80.7804 129.71 80.7804 118.85C80.7804 116.77 81.1104 114.77 81.7104 112.89C77.7904 110.16 74.5004 106.59 72.0904 102.45C69.2904 107.28 67.6904 112.88 67.6904 118.85C67.6904 135.08 79.4904 148.55 94.9704 151.16C96.7504 151.3 98.5404 151.4 100.35 151.4C102.3 151.4 104.22 151.3 106.13 151.13C121.53 148.45 133.24 135.02 133.24 118.85H133.25Z"
    fill="url(#paint9_radial_249_1151)"
  />
  <path
    d="M100.47 37.0801C75.5503 37.0801 53.2403 48.2501 38.2403 65.8301C36.1603 72.2101 35.0303 79.0101 35.0303 86.0801C35.0303 89.5701 35.3103 93.0001 35.8403 96.3501C45.1703 69.6301 70.6103 50.4101 100.47 50.4101C130.33 50.4101 155.49 69.4201 164.95 95.9001C165.43 92.7001 165.69 89.4201 165.69 86.0901C165.69 78.8601 164.5 71.9101 162.33 65.4101C147.34 48.0801 125.2 37.0901 100.48 37.0901L100.47 37.0801Z"
    fill="url(#paint10_radial_249_1151)"
  />
  <path
    d="M100.471 135.3C109.551 135.3 116.921 127.94 116.921 118.85C116.921 117.36 116.701 115.93 116.331 114.56C111.611 117.22 106.161 118.74 100.351 118.74C94.5407 118.74 89.2507 117.26 84.5707 114.68C84.2207 116.02 84.0107 117.41 84.0107 118.86C84.0107 127.94 91.3707 135.31 100.461 135.31L100.471 135.3Z"
    fill="url(#paint11_radial_249_1151)"
  />
  <path
    d="M130.42 98.8701C134.25 104.59 136.49 111.47 136.49 118.86C136.49 130.66 130.79 141.15 121.99 147.72C132.07 144.18 141.02 138.24 148.16 130.58C149.08 126.82 149.58 122.9 149.58 118.86C149.58 104.09 143.05 90.8601 132.74 81.8601C132.92 83.2401 133.02 84.6501 133.02 86.0901C133.02 90.6301 132.09 94.9501 130.42 98.8801V98.8701Z"
    fill="url(#paint12_radial_249_1151)"
  />
  <path
    d="M159 57.29C148.36 35.65 126.1 20.75 100.36 20.75C74.62 20.75 52.06 35.84 41.5 57.71C56.8 42.95 77.59 33.84 100.48 33.84C123.37 33.84 143.75 42.77 159.01 57.28L159 57.29Z"
    fill="url(#paint13_radial_249_1151)"
  />
  <path
    d="M48.1202 118.85C48.1202 101.81 56.3102 86.6399 68.9602 77.0799C72.3602 65.1799 82.3402 56.0699 94.7102 53.9099C66.7802 56.3599 43.9002 76.4099 37.2402 102.91C39.4902 111.39 43.4102 119.18 48.6202 125.93C48.3102 123.61 48.1302 121.25 48.1302 118.85H48.1202Z"
    fill="url(#paint14_radial_249_1151)"
  />
  <path
    d="M105.84 53.8799C118.21 55.9699 128.22 65.0199 131.69 76.8599C144.5 86.4099 152.82 101.68 152.82 118.85C152.82 121.13 152.66 123.37 152.37 125.58C157.53 118.79 161.4 110.97 163.6 102.47C156.77 76.0599 133.79 56.1599 105.83 53.8799H105.84Z"
    fill="url(#paint15_radial_249_1151)"
  />
  <defs>
    <radialGradient
      id="paint0_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(100.22 151.43) scale(130.03 130.03)"
    >
      <stop stop-color="#F28021" />
      <stop offset="1" stop-color="#EE6026" />
    </radialGradient>
    <radialGradient
      id="paint1_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(100.22 0.499935) scale(130.03 130.03)"
    >
      <stop stop-color="#F28021" />
      <stop offset="1" stop-color="#EE6026" />
    </radialGradient>
    <radialGradient
      id="paint2_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(100.22 0.499988) scale(130.03 130.03)"
    >
      <stop stop-color="#F28021" />
      <stop offset="1" stop-color="#EE6026" />
    </radialGradient>
    <radialGradient
      id="paint3_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(100.221 0.49997) scale(130.03 130.03)"
    >
      <stop stop-color="#F28021" />
      <stop offset="1" stop-color="#EE6026" />
    </radialGradient>
    <radialGradient
      id="paint4_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(100.22 0.50002) scale(130.03 130.03)"
    >
      <stop stop-color="#F28021" />
      <stop offset="1" stop-color="#EE6026" />
    </radialGradient>
    <radialGradient
      id="paint5_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(100.25 0.499906) scale(130.02)"
    >
      <stop stop-color="#F28021" />
      <stop offset="1" stop-color="#EE6026" />
    </radialGradient>
    <radialGradient
      id="paint6_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(100.22 0.499832) scale(130.03 130.03)"
    >
      <stop stop-color="#F28021" />
      <stop offset="1" stop-color="#EE6026" />
    </radialGradient>
    <radialGradient
      id="paint7_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(100.22 0.499798) scale(130.03 130.03)"
    >
      <stop stop-color="#F28021" />
      <stop offset="1" stop-color="#EE6026" />
    </radialGradient>
    <radialGradient
      id="paint8_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(102.72 85.5201) scale(63 63)"
    >
      <stop offset="0.42" stop-color="#EE6026" />
      <stop offset="1" stop-color="#EE6026" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint9_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(102.72 0.500014) scale(63 63)"
    >
      <stop offset="0.42" stop-color="#EE6026" />
      <stop offset="1" stop-color="#EE6026" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint10_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(102.72 0.500078) scale(63 63)"
    >
      <stop offset="0.42" stop-color="#EE6026" />
      <stop offset="1" stop-color="#EE6026" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint11_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(102.721 0.50005) scale(63 63)"
    >
      <stop offset="0.42" stop-color="#EE6026" />
      <stop offset="1" stop-color="#EE6026" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint12_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(102.72 0.500105) scale(63 63)"
    >
      <stop offset="0.42" stop-color="#EE6026" />
      <stop offset="1" stop-color="#EE6026" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint13_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(102.72 0.500002) scale(63 63)"
    >
      <stop offset="0.42" stop-color="#EE6026" />
      <stop offset="1" stop-color="#EE6026" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint14_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(102.72 0.499914) scale(63 63)"
    >
      <stop offset="0.42" stop-color="#EE6026" />
      <stop offset="1" stop-color="#EE6026" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint15_radial_249_1151"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(102.72 85.5199) scale(63 63)"
    >
      <stop offset="0.48" stop-color="#EE6026" />
      <stop offset="1" stop-color="#EE6026" stop-opacity="0" />
    </radialGradient>
  </defs>
</svg>
