import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';

import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { OnDynamicMount, OnDynamicChanges, OnDynamicData, DynamicContentChild } from 'ngx-dynamic-hooks';
import { AnimationItem } from 'ngx-lottie/lib/symbols';

export type Shape =
  | 'arrow-07'
  | 'arrow-08'
  | 'arrow-11'
  | 'arrow-20'
  | 'circle-01'
  | 'squiggle-01'
  | 'squiggle-03'
  | 'squiggle-05'
  | 'squiggle-07'
  | 'squiggle-10';
export const shapes: Shape[] = [
  'arrow-07',
  'arrow-08',
  'arrow-11',
  'arrow-20',
  'circle-01',
  'squiggle-01',
  'squiggle-03',
  'squiggle-05',
  'squiggle-07',
  'squiggle-10',
];
@Component({
  selector: 'app-text-shape',
  templateUrl: './text-shape.component.html',
  standalone: true,
  imports: [CommonModule, LottieComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextShapeComponent implements OnInit, OnChanges {
  private zone = inject(NgZone);
  private platformId = inject(PLATFORM_ID);

  shapes: { [key: string]: Shape[] } = {
    textbg: ['squiggle-01', 'squiggle-03', 'squiggle-05', 'squiggle-07', 'squiggle-10', 'circle-01'],
    arrow: ['arrow-07', 'arrow-08', 'arrow-11', 'arrow-20'],
    standalone: ['squiggle-03', 'squiggle-07', 'squiggle-01'],
  };

  options$ = new BehaviorSubject<AnimationOptions>({});
  private _unsubscribe$ = new Subject<void>();

  cd = inject(ChangeDetectorRef);
  delay = 500;
  started = false;
  useShape?: string;

  @Input() shape?: Shape;
  @Input() mirror: boolean = false;
  @Input() cssClasses: string = '';
  @Input() text: string = '';
  @Input() shapeColor: string = '';

  @Input() type: 'arrow' | 'textbg' | 'standalone' = 'textbg';
  @Input() autoplay: boolean = false;
  @Input() startAnimation: boolean = false;

  @ViewChild('lottie') lottie?: LottieComponent;

  ngOnInit(): void {
    this.useShape = this.shape || this.getRandomFromArray(this.shapes[this.type]);
    this.addAndPlayAnimation();
  }

  public getRandomFromArray(arr: any[]) {
    if (!arr) {
      return '';
    }
    return arr[Math.floor(Math.random() * arr.length)];
  }

  getLottiePath() {
    return `/assets/lottie-files/${this.useShape}.json`;
  }

  onDynamicMount(data: OnDynamicData): void {
    const context = data.context;
    this.delay = context['delayAnimation'] || this.delay;
    this.started = data.context?.['startShapeAnimation'] || false;
  }

  onDynamicChanges(data: any) {
    this.started = data.context?.['startShapeAnimation'];

    this.addAndPlayAnimation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['startAnimation'] && changes['startAnimation']?.currentValue === true) {
      this.started = true;

      this.addAndPlayAnimation();
    }
  }
  // todo: setDirection omdraaien, animatie moet andersom
  playerSettings(e: AnimationItem) {
    // console.log(e);
    // e.setDirection(-1);
    // e.play();
  }
  addAndPlayAnimation() {
    if (this.started === true || this.autoplay) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.options$.next({
            path: this.getLottiePath(),
            loop: false,
          });
        }, this.delay);
      });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
