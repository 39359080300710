<footer class="footer bg-dark text-light" id="footer" [class.big-text-spacer-top]="!hidePayoff">
  <app-big-text *ngIf="!hidePayoff" class="footer__title text-dark" [text]="'footer-titel' | fromDictionary" [speed]="1"></app-big-text>

  <div class="container">
    <section class="section-inset-y footer__payoff">
      <div class="row">
        <div class="col-md-7">
          <h2 class="fs-1 font-display fw-400 mb-0 text-max-width footer__payoff__text">
            {{ 'footer-payoff' | fromDictionary }}
          </h2>
        </div>
        <div class="col-md-5">
          <a
            [href]="'mailto:' + ('brilliant-orange-emailadres' | fromDictionary)"
            class="button button-outline mt-4 button--primary has--dot"
          >
            <span class="text-white">{{ 'footer-afspraak-label' | fromDictionary }}</span>
          </a>
        </div>
      </div>
    </section>

    <section class="footer__contact row">
      <div class="col-md-7 d-lg-flex">
        <dl class="mb-3 mb-md-0">
          <dt class="text-primary type-body-xs">{{ 'footer-adres-label' | fromDictionary }}</dt>
          <dd class="fs-5">
            {{ 'brilliant-orange-adres' | fromDictionary }}
            <br />
            {{ 'brilliant-orange-postcode' | fromDictionary }}
            {{ 'brilliant-orange-plaats' | fromDictionary }}
          </dd>
        </dl>
        <dl class="mx-md-auto mb-3 mb-md-0">
          <dt class="text-primary type-body-xs">{{ 'footer-telefoonnummer-label' | fromDictionary }}</dt>
          <dd class="fs-5">
            <a class="button-link button button--light" [href]="'tel:' + ('brilliant-orange-telefoonnummer' | fromDictionary)">
              {{ 'brilliant-orange-telefoonnummer' | fromDictionary }}
            </a>
          </dd>
        </dl>
      </div>
      <div class="col-md-5">
        <dl>
          <dt class="text-primary type-body-xs">{{ 'footer-email-label' | fromDictionary }}</dt>
          <dd class="fs-5">
            <a class="button-link button button--light" [href]="'mailto:' + ('brilliant-orange-emailadres' | fromDictionary)">
              {{ 'brilliant-orange-emailadres' | fromDictionary }}
            </a>
          </dd>
        </dl>
      </div>
      <app-json-ld [json]="schema"></app-json-ld>
    </section>

    <nav class="footer__nav pt-5 section-inset-b row">
      <div class="col-md-7">
        <ul class="footer__nav__list d-flex type--dots">
          <li class="footer__nav__list__item" *ngFor="let item of menu">
            <fstr-link
              [cssClasses]="'button button-link button--light'"
              [button]="{ text: item.title, url: item.url ? item.url : '', routerLink: item.routerLink }"
            ></fstr-link>
          </li>
          <li class="footer__nav__list__item">
            <button (click)="openCookie()" class="button button-link button--light">{{ 'cookie-settings' | fromDictionary }}</button>
          </li>
        </ul>
      </div>

      <div class="col-md-5 d-md-flex">
        <ul class="footer__nav__list pt-4 pt-md-0 d-flex">
          <li class="footer__nav__list__item" *ngFor="let item of socials">
            <fstr-link
              [cssClasses]="'button button-link button--light'"
              [button]="{ text: item.title, url: item.url ? item.url : '' }"
            ></fstr-link>
          </li>
        </ul>
        <ul class="footer__nav__list pt-4 pt-md-0 d-flex me-0 ms-auto">
          <li class="footer__nav__list__item" *ngFor="let item of highlightMenu">
            <fstr-link
              [cssClasses]="'button button-link button--secondary'"
              [button]="{ text: item.title, url: item.url ? item.url : '', routerLink: item.routerLink }"
            ></fstr-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</footer>
