import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
import { GridOverlayComponent } from './components/grid-overlay/grid-overlay.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ImageModule } from '@teamfoster/sdk/image';
import { SeoModule } from '../seo/seo.module';
import { AlertComponent } from './components/alert/alert.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { BigTextComponent } from '../standalone/big-text/big-text.component';
import { ButtonModule } from '@teamfoster/sdk/button';

import { DynamicHooksModule } from 'ngx-dynamic-hooks';
import { TextShapeComponent } from '../standalone/text-shape/text-shape.component';
import { LogoComponent } from '../standalone/logo/logo.component';

const moduleExports = [LoaderIndicatorComponent, TopbarComponent, FooterComponent, GridOverlayComponent, AlertComponent];

@NgModule({
  declarations: [...moduleExports],
  exports: [...moduleExports, HighlightPipe],
  providers: [],
  imports: [
    CommonModule,
    RouterModule,
    InViewModule,
    TextUtilityModule,
    Html5VideoPlayerModule,
    IconModule,
    DictionaryNgrxModule,
    SeoModule,
    ImageModule,
    DynamicHooksModule,
    BigTextComponent,
    ButtonModule,
    TextShapeComponent,
    LogoComponent,
    HighlightPipe,
  ],
})
export class CoreModule {}
