import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  inject,
} from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * @param {string} key - An optional key used as a prefix on on CSS Variables. For instance when a key of hero is used with the chars plugin, it will changethe CSS variable --char-index to --hero-char-index. This should be used if multiple splits have been performed on the same element, or to resolve conflicts with other libraries.
 * @param {number} duration - in milliseconds, sets css variable so we can emit a function when animation is done. Use the css variable set by this component to time the CSS animation to complete exactly in this time
 * @param {EventEmitter} animationDone - only works in combination with @input duration and correct usage of duration in css
 */

@Directive({
  selector: '[splitting]',
  standalone: true,
})
export default class SplittingDirective implements AfterViewInit, OnChanges {
  @Input() by: 'words' | 'chars' | 'lines' = 'chars'; // Other possible (existing) plugins:  'items' | 'grid' | 'cols' | 'rows' | 'cells'
  @Input() key?: string;

  @Input() duration?: number;
  //@Input() startTextAnimation: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() startTextAnimation: boolean = false;
  @Output() animationDone = new EventEmitter<void>();

  private platformId = inject(PLATFORM_ID);
  private zone = inject(NgZone);
  elRef = inject(ElementRef);

  ngOnChanges(changes: SimpleChanges): void {
    const textAnimation = changes['startTextAnimation'];
    if (textAnimation && textAnimation.previousValue === false && textAnimation.currentValue === true) {
      this.startAnimation();
    }
  }

  ngAfterViewInit(): void {
    const target = this.elRef.nativeElement;
    if (isPlatformBrowser(this.platformId) && target && (<any>window).Splitting) {
      (<any>window).Splitting({ target: target, by: this.by });
      this.elRef.nativeElement.classList.add('splitting--loaded');
    }

    if (this.startTextAnimation === true) {
      this.startAnimation();
    }
  }

  startAnimation() {
    const target = this.elRef.nativeElement;

    if (isPlatformBrowser(this.platformId) && target) {
      this.emitAnimationDone();
    }
  }

  emitAnimationDone() {
    if (this.duration) {
      this.elRef.nativeElement.style.setProperty('--splitting-duration', `${this.duration}ms`);
      this.elRef.nativeElement.classList.add('anim--active');

      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          //if (!environment.production) {
          //  console.log('Animation finished for', this.elRef.nativeElement);
          //}
          this.animationDone.emit();
        }, this.duration);
      });
    }
  }
}
